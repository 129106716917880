import { useEffect } from 'react';

/**
 * Alternative redirect for use with React Router
 * Sometimes using <Navigate /> with <Route> causes an infinite loop
 */
export const Redirect = ({ to }: { to: string }) => {
    useEffect(() => {
        location.href = to;
    }, []);

    return null;
};
