import { filterArticlesByPaymentSchedule } from '@src/utils/helpers/articleHelpers';
import { isEqual, isUnique } from '@src/utils/helpers/collections';
import { PaymentInterval } from '@src/utils/helpers/priceHelpers';
import { ArticleByPaymentSchedule } from '@src/utils/hooks/api/types/articleByPaymentSchedule';
import { ChangeMembershipProps } from '@src/utils/hooks/api/useChangeMembership';
import { headers } from '@src/utils/hooks/fetchOptions';
import { assign, createMachine, StateMachine } from 'xstate';

import { getMembershipChangeOrderLink } from 'services/API/get-order-link';
import { EventError } from '../ChangeMembershipFlow.types';

interface Context {
    renew: boolean;
    index: number;
    route: string;
    interval: PaymentInterval | null;
    membership: null | ChangeMembershipProps;
    extras: ArticleByPaymentSchedule[];
    articles: ArticleByPaymentSchedule[];
    member: null | OptionalMemberProps;
    error: null | string;
}

type OptionalMemberProps = {
    phoneNumber?: string;
};

type Events =
    | { type: 'UPDATE.MEMBERSHIP'; values: ChangeMembershipProps }
    | { type: 'SET.ERROR'; value: EventError }
    | { type: 'NEXT_STEP' }
    | { type: 'PREVIOUS_STEP' }
    | { type: 'ADD.EXTRA'; value: ArticleByPaymentSchedule }
    | { type: 'REMOVE.EXTRA'; value: ArticleByPaymentSchedule }
    | { type: 'SET.ARTICLES'; value: ArticleByPaymentSchedule[] }
    | { type: 'UPDATE.PHONE'; value: string }
    | { type: 'SUBMIT' };

type EventStates =
    | { value: 'choose'; context: Context }
    | { value: 'extras'; context: Context }
    | { value: 'overview'; context: Context }
    | { value: 'done'; context: Context }
    | { value: 'save'; context: Context }
    | { value: 'failure'; context: Context };

/**
 * Promises
 * https://xstate.js.org/docs/guides/communication.html#invoking-promises
 */

const handleMemberProps = (member: OptionalMemberProps) =>
    new Promise((resolve, reject) => {
        fetch('/member/update-member-info', {
            method: 'POST',
            headers,
            body: JSON.stringify({
                phoneNumber: member.phoneNumber
            })
        })
            .then((response) => {
                resolve(response.json());
            })
            .catch((error) => reject(error));
    });

const handleGetOrderLink = (context: Context) =>
    new Promise((resolve, reject) => {
        const { membership, extras, articles, renew } = context;

        if (!membership) throw new Error('Membership is not defined');

        getMembershipChangeOrderLink(
            membership?.id,
            filterArticlesByPaymentSchedule(extras, articles),
            renew ? membership?.peopleMembership.startDate : undefined
        )
            .then((orderLink) => {
                window.location.href = orderLink;
                return resolve('Redirecting to order link');
            })
            .catch((error) => reject(error));
    });

const fetchChangeMembership = (context: Context) => {
    const { member } = context;

    if (member) {
        return Promise.allSettled([handleMemberProps(member), handleGetOrderLink(context)]);
    } else {
        return handleGetOrderLink(context);
    }
};

/**
 * Implement state machine
 */

export let changeMembershipMachine: StateMachine<Context, any, Events, EventStates>;
export const generateChangeMembershipMachine = (context?: Partial<Context>) => {
    changeMembershipMachine = createMachine<Context, Events, EventStates>({
        preserveActionOrder: true,
        predictableActionArguments: true,
        id: 'changeMembership',
        initial: 'choose',
        context: {
            renew: false,
            index: 0,
            route: '',
            interval: null,
            membership: null,
            extras: [],
            articles: [],
            error: null,
            member: null,
            ...context
        },
        states: {
            choose: {
                entry: [assign(() => ({ index: 0, route: '' })), 'updateRoute'],
                on: {
                    'UPDATE.MEMBERSHIP': {
                        actions: assign({
                            // If the membership is changed, reset the extras
                            extras: ({ membership, extras }, event) =>
                                membership?.id !== event.values.id ? [] : extras,
                            membership: (_, event) => event.values,
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            error: (_, _event) => null
                        })
                    },
                    'SET.ERROR': {
                        actions: assign({
                            error: (_, event) => event.value
                        })
                    },
                    NEXT_STEP: {
                        target: 'extras'
                    }
                }
            },
            extras: {
                entry: [assign(() => ({ index: 1, route: 'extras' })), 'updateRoute'],
                on: {
                    'ADD.EXTRA': {
                        actions: assign({
                            extras: (context, event) => isUnique([...context.extras, event.value])
                        })
                    },
                    'REMOVE.EXTRA': {
                        actions: assign({
                            extras: (context, event) =>
                                context.extras.filter((extra) => !isEqual(extra, event.value))
                        })
                    },
                    'SET.ARTICLES': {
                        actions: assign({
                            articles: (_context, event) => event.value
                        })
                    },
                    NEXT_STEP: {
                        target: 'overview'
                    },
                    PREVIOUS_STEP: {
                        target: 'choose'
                    }
                }
            },
            overview: {
                entry: [assign(() => ({ index: 2, route: 'overview' })), 'updateRoute'],
                on: {
                    'UPDATE.PHONE': {
                        actions: assign({
                            member: (_, event) => ({ phoneNumber: event.value })
                        })
                    },
                    SUBMIT: {
                        target: 'save'
                    },
                    PREVIOUS_STEP: {
                        target: 'extras'
                    }
                }
            },
            save: {
                invoke: {
                    id: 'fetchUpdate',
                    src: fetchChangeMembership,
                    onError: [
                        {
                            target: 'failure',
                            actions: assign({
                                error: (_, event) => event.data.message
                            })
                        }
                    ]
                }
            },
            failure: {
                on: {
                    SUBMIT: {
                        target: 'save'
                    },
                    PREVIOUS_STEP: {
                        target: 'extras'
                    }
                }
            }
        }
    });
};
