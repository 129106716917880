import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Text } from '@basic-fit/design-system';
import { Box } from '@src/components/Box/Box';
import { Button } from '@src/components/Buttons/Button';
import { Skeleton } from '@src/components/Skeletons/Skeleton';
import {
    NotesAndroid,
    NotesIOS,
    NotesOverview
} from '@src/pages/Payments/components/Notes.components';
import { useMember } from '@src/services/member/MemberProvider';
import { isEmpty } from '@src/utils/helpers/collections';
import { getDayAndMonth } from '@src/utils/helpers/date';
import { getMemberName } from '@src/utils/helpers/memberHelpers';
import { isApp, isIOS } from '@src/utils/helpers/window';
import { DebitsProps, NoteArticle } from '@src/utils/hooks/api/types/debits';
import { LogTransaction } from '@src/utils/hooks/api/types/transactions';
import { useFetch } from '@src/utils/hooks/api/useFetch';
import { useAwaitSpinner } from '@src/utils/hooks/useAwaitSpinner';
import { saveAs } from 'file-saver';
import { DateTime } from 'luxon';
import { getCountryForApiRequest } from 'parameters/languages';

import googleTagManagerPush from '../../../../services/google-tag-manager-push';

export const Notes = () => {
    const { t } = useTranslation();

    const { state: member } = useMember();
    const {
        data: debits,
        isLoading,
        isError: apiError
    } = useFetch<DebitsProps>('/payments/get-debits');

    const { data: transactions } = useFetch<LogTransaction[]>('/payments/get-transaction-log');

    const [noDebits, setNoDebits] = useState(true);
    const [pdfEnabled, setPdfEnabled] = useState(false);

    const [tableData, setTableData] = useState<NoteArticle[]>([]);

    const [upcomingDebitDates, setUpcomingDebitDates] = useState<string[]>([]);
    const dateFormat = 'dd-MM-yyyy';

    const getArticleDescription = (description: string) => {
        if (description.includes('Yanga')) {
            return 'Yanga';
        }
        return description;
    };

    const getDelayedTransactions = (transactions: LogTransaction[]) => {
        const delayedTransactions = transactions.filter((transaction) => {
            return (
                transaction.dueDate &&
                new Date(transaction.dueDate) > new Date() &&
                transaction.amount < 0
            );
        });

        return delayedTransactions as (LogTransaction & { dueDate: string })[];
    };

    const delayedTransactions = useMemo(() => {
        return transactions ? getDelayedTransactions(transactions) : [];
    }, [transactions]);

    const isDebit = (description: string) => {
        if (description.includes('Online Coach') || description.includes('Intro')) {
            return false;
        }
        return true;
    };

    useEffect(() => {
        setPdfEnabled(getCountryForApiRequest(member) === 'France' && !delayedTransactions.length);

        if (delayedTransactions.length) {
            setTableData(
                delayedTransactions.map((transaction) => ({
                    amount: Math.abs(transaction.amount),
                    description: `${transaction.description} ${DateTime.fromISO(
                        transaction.dueDate
                    ).toFormat('dd-MM')}`,
                    dateTime: transaction.date,
                    tax: 0,
                    taxPercentage: 0
                }))
            );

            setUpcomingDebitDates(
                Array.from(
                    new Set(
                        delayedTransactions.map((transaction) =>
                            DateTime.fromISO(transaction.dueDate).toFormat('dd-MM-yyyy')
                        )
                    )
                )
            );
        } else {
            if (!debits || isEmpty(debits)) return;

            for (const debit of debits.articles) {
                if (!isDebit(debit.description)) continue;

                setTableData((prevData) => [
                    ...prevData,
                    {
                        ...debit,
                        description:
                            getArticleDescription(debit.description) +
                            ` ${getDayAndMonth(debits.startDate, false)} / ${getDayAndMonth(
                                debits.endDate,
                                false
                            )}`
                    }
                ]);
            }
        }

        setNoDebits(false);

        if (!debits?.upcomingDebits || isEmpty(debits?.upcomingDebits)) return;

        const dates = debits.upcomingDebits.map((debit) => {
            return DateTime.fromISO(debit.debitStart).toFormat(dateFormat);
        });

        setUpcomingDebitDates((state) => [...state, ...dates]);
    }, [member, debits, delayedTransactions, transactions]);

    /**
     * Download handler, state logic for download events
     */
    const { awaiting: isLoadingPdf, awaitSpin } = useAwaitSpinner();
    const [disabled, setDisabled] = useState(false);
    const [success, setSuccess] = useState(false);
    const [pdfError, setPdfError] = useState(false);

    const getNotePdf = async () => {
        try {
            setDisabled(true);
            await awaitSpin(() => startDownload());
            setSuccess(true);
        } catch (e) {
            setPdfError(true);
            setDisabled(false);
        }
    };

    const startDownload = async () => {
        const response = await fetch(`/payments/get-note`);
        if (response.ok) {
            const filename = `${getMemberName(member).replace(' ', '-')}-note-${
                debits.startDate
            }.pdf`;
            const file = await response.blob();
            const url = URL.createObjectURL(file);
            saveAs(url, filename);
            googleTagManagerPush.noteDownload();
        } else {
            googleTagManagerPush.noteDownloadError(response.statusText);
            throw Error(response.statusText);
        }
    };

    const NotesDownload = () => {
        if (isApp() && isIOS()) {
            return <NotesIOS />;
        }
        if (isApp() && !isIOS()) {
            return <NotesAndroid />;
        }
        return (
            <>
                <NotesOverview tableData={tableData} dates={upcomingDebitDates} />

                <Button
                    icon={success ? 'tick' : ''}
                    text={success ? t('242') : t('notes.button.download')}
                    className="mt-m w-auto p-s m-auto"
                    data-cy="download-note"
                    onClick={getNotePdf}
                    loading={isLoadingPdf}
                    disabled={disabled || pdfError}
                />
            </>
        );
    };

    return (
        <Box variant="grey" data-cy="notes">
            <Heading size={5} className="mt-xxs" data-testid="notes-title">
                {t('payments.future.periods')}
            </Heading>

            {isLoading ? (
                <Skeleton type={'text'} lines={3} />
            ) : noDebits ? (
                <Text size={2} data-testid="no-debits">
                    {t('payments.future.periods.no.fees')}
                </Text>
            ) : (
                <>
                    {apiError ? (
                        <>
                            <Text size={2} className="my-xs">
                                {t('notes.description')}
                            </Text>
                            <Text size={2} className="text-orange mt-xs">
                                {t('notes.no.details')}
                            </Text>
                        </>
                    ) : pdfEnabled ? (
                        <NotesDownload />
                    ) : (
                        <NotesOverview tableData={tableData} dates={upcomingDebitDates} />
                    )}
                </>
            )}

            {pdfError && (
                <Text size={2} className="text-orange mt-xs">
                    {t('payments.download.modal.error_pdf')}
                </Text>
            )}
        </Box>
    );
};
